// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-songs-js": () => import("./../src/pages/songs.js" /* webpackChunkName: "component---src-pages-songs-js" */),
  "component---src-pages-translations-js": () => import("./../src/pages/translations.js" /* webpackChunkName: "component---src-pages-translations-js" */),
  "component---src-templates-artist-js": () => import("./../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-song-js": () => import("./../src/templates/song.js" /* webpackChunkName: "component---src-templates-song-js" */),
  "component---src-templates-translation-js": () => import("./../src/templates/translation.js" /* webpackChunkName: "component---src-templates-translation-js" */)
}

